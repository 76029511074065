<template>
  <div class="gallery">
    <div class="inner">
      <template v-if="!images.length"><h1>No images in gallery</h1></template>
      <template>
        <img :src="'/g/' + image" v-for="(image, i) in images" :alt="image" :key="i" @click="onImgClick(i)">
      </template>
    </div>
    <transition name="img-info" appear>
      <div class="img-container" @click.self="closeImg" v-if="imageClicked">
        <img :src="'/g/' + images[imageIndex] || ''" :alt="images[imageIndex] || 'empty'">
        <div class="btn" @click="switchImg" v-if="imageIndex < images.length - 1">
          <img src="../assets/img/arrow.svg" alt="">
        </div>
        <div class="btn translated" @click="switchImg(true)" v-if="imageIndex > 0">
          <img src="../assets/img/arrow.svg" alt="">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from "../api/api";

export default {
  name: "Gallery",
  data() {
    return {
      images: [],
      imageClicked: false,
      imageFilename: '',
      imageIndex: -1
    }
  },
  created() {
    api.getData('get/g').then(r => {
      // console.log('resp', r)
      if (!r.data.error) {
        const images = r.data.gallery.images
        if (images !== '') this.images = images.split(',')
      }
    }).catch(e => {
      console.error(e)
    })
  },
  methods: {
    onImgClick(index) {
      // this.imageFilename = imageFile
      this.imageIndex = index
      this.imageClicked = true
    },
    closeImg() {
      this.imageClicked = false
      // this.imageFilename = ''
      this.imageIndex = -1
    },
    switchImg(toPrev) {
      // alert(toPrev)
      if (toPrev === true) this.imageIndex--
      else this.imageIndex++
      // this.imageFilename = this.images[this.imageIndex]
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/gallery.scss"></style>
